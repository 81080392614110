<template>
  <!-- 作品详情配置列表 -->
  <body-layout>
    <div
      class="actions flex "
      slot="actions"
    >
      <a-button
        type="danger"
        class="mr-3"
        @click="batchDel"
        v-if="selectedRowKeys.length"
        icon="delete"
        v-has="'system:font:list:button:delete'"
      >删除</a-button>
<!--       <a-button
        type="default"
        class="mr-3"
        @click="batchCopy"
        v-if="selectedRowKeys.length"
        v-has="'system:font:list:button:delete'"
      >创建副本</a-button>
      <a-button
        type="primary"
        class="mr-3"
        @click="pSync"
        v-if="selectedRowKeys.length"
        v-has="'system:font:list:button:delete'"
      >同步</a-button> -->
      <a-button
        type="primary"
        @click="openAddForm"
        v-has="'system:font:list:button:add'"
        icon="plus"
      >新建</a-button>
    </div>
    <div>
      <a-table
        style="border-top:1px solid rgb(232,232,232)"
        ref="table"
        size="middle"
        rowKey="id"
        :columns="columns"
        :dataSource="dataSource"
        :customRow="clickRow"
        :pagination="ipagination"
        :loading="loading"
        :scroll="{ x: 'max-content', y: 'calc(100vh - 353px)' }"
        :rowSelection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange,columnWidth:80 }"
        @change="handleTableChange"
      >
        <span
          slot="type"
          slot-scope="text, record"
        >
          {{ record.cardList[0] | typeFilter }}
        </span>
        <span
          slot="canChapter"
          slot-scope="text, record"
        >
        {{record.canChapter?'是':'否'}}
        </span>
        <span
          slot="placeholder"
          slot-scope="text, record"
        >
        {{record.cardList[0].dataList[0].placeholder}}
        </span>
        <span
          slot="departmentNames"
          slot-scope="text, record"
        >
          <a-tag
            v-for="one in record.departments"
            :key="one.departId"
          >{{ one.departName }}</a-tag>
        </span>

        <span
          slot="positionNames"
          slot-scope="text, record"
        >
          <a-tag
            v-for="one in record.positions"
            :key="one.positionId"
          >{{ one.positionName }}</a-tag>
        </span>
      </a-table>
    </div>
    <components-modal
      :type="type"
      :open="drawerShow"
      :cur-row="curRow"
      :isForm="isNewForm"
      @closed="drawerClosed"
    />
  </body-layout>
</template>

<script>
import { JeecgListMixin } from '@/mixins/JeecgListMixin'
import componentsModal from './modules/components/components-modal.vue'

export default {
  mixins: [JeecgListMixin],
  components: {
    componentsModal,
  },
  data() {
    return {
      filters: {
        type: this.type,
        relationId: this.relationId,
      },
      columns: [
        {
          title: '序号',
          dataIndex: 'sort',
          width: 100,
        },
        {
          title: '名称',
          dataIndex: 'title',
        },
/*         {
          title: '类型',
          dataIndex: 'type',
          scopedSlots: { customRender: 'type' },
          width: 280,
        }, */
/*         {
          title: '是否必填',
          scopedSlots: { customRender: 'canChapter' },
          dataIndex: 'canChapter',
          width: 280,
        }, */
/*         {
          title: '录入提示',
          scopedSlots: { customRender: 'placeholder' },
          dataIndex: 'placeholder',

        }, */
      ],
      domain:"COMPONENTS",
      dataSource: [],
      url: {
        syncUser: '/act/process/extActProcess/doSyncUser',
        list: {
          path: '/productDetail/page',
        },
        copyBatch: 'productDetail/copy',
        delete: '/fonts/delete',
        deleteBatch: '/productDetail/',
        syncPath: 'productDetail/sync',
      },
    }
  },
  filters: {
    typeFilter(data) {
      const typeMap = {
        DYNAMIC_FORM: `动态表单${
          data.dataList[0].type == 'INPUT'
            ? '-文本录入'
            : data.dataList[0].type == 'SELECT'
            ? '-下拉选项'
            : data.dataList[0].type == 'TAG'
            ? '-标签'
            : ''
        }`,
        INPUT: '单行文本',
        INPUT_NUMBER: '数字输入框',
        TEXTAREA: '多行文本',
        RADIO: '单项选择',
        SELECT: '下拉选择',
        TAG: '标签',
        FILE_UPLOAD: '图片上传',
        DYNAMIC_SELECT: '动态选择',
      }
      return typeMap[data.type]
    },
  },
  props: {
    type: {
      type: String,
      default: () => 'GLOBAL',
    },
    relationId: {
      type: String,
      default: () => '',
    },
  },
}
</script>

<style lang="less" scoped>
/deep/ .border-bottom {
  border: none;
}
</style>
