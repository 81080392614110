var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "body-layout",
    [
      _c(
        "div",
        {
          staticClass: "actions flex",
          attrs: { slot: "actions" },
          slot: "actions",
        },
        [
          _vm.selectedRowKeys.length
            ? _c(
                "a-button",
                {
                  directives: [
                    {
                      name: "has",
                      rawName: "v-has",
                      value: "system:font:list:button:delete",
                      expression: "'system:font:list:button:delete'",
                    },
                  ],
                  staticClass: "mr-3",
                  attrs: { type: "danger", icon: "delete" },
                  on: { click: _vm.batchDel },
                },
                [_vm._v("删除")]
              )
            : _vm._e(),
          _c(
            "a-button",
            {
              directives: [
                {
                  name: "has",
                  rawName: "v-has",
                  value: "system:font:list:button:add",
                  expression: "'system:font:list:button:add'",
                },
              ],
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.openAddForm },
            },
            [_vm._v("新建")]
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("a-table", {
            ref: "table",
            staticStyle: { "border-top": "1px solid rgb(232,232,232)" },
            attrs: {
              size: "middle",
              rowKey: "id",
              columns: _vm.columns,
              dataSource: _vm.dataSource,
              customRow: _vm.clickRow,
              pagination: _vm.ipagination,
              loading: _vm.loading,
              scroll: { x: "max-content", y: "calc(100vh - 353px)" },
              rowSelection: {
                selectedRowKeys: _vm.selectedRowKeys,
                onChange: _vm.onSelectChange,
                columnWidth: 80,
              },
            },
            on: { change: _vm.handleTableChange },
            scopedSlots: _vm._u([
              {
                key: "type",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm._f("typeFilter")(record.cardList[0])) +
                        "\n        "
                    ),
                  ])
                },
              },
              {
                key: "canChapter",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(record.canChapter ? "是" : "否") +
                        "\n        "
                    ),
                  ])
                },
              },
              {
                key: "placeholder",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _vm._v(
                      "\n        " +
                        _vm._s(record.cardList[0].dataList[0].placeholder) +
                        "\n        "
                    ),
                  ])
                },
              },
              {
                key: "departmentNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.departments, function (one) {
                      return _c("a-tag", { key: one.departId }, [
                        _vm._v(_vm._s(one.departName)),
                      ])
                    }),
                    1
                  )
                },
              },
              {
                key: "positionNames",
                fn: function (text, record) {
                  return _c(
                    "span",
                    {},
                    _vm._l(record.positions, function (one) {
                      return _c("a-tag", { key: one.positionId }, [
                        _vm._v(_vm._s(one.positionName)),
                      ])
                    }),
                    1
                  )
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("components-modal", {
        attrs: {
          type: _vm.type,
          open: _vm.drawerShow,
          "cur-row": _vm.curRow,
          isForm: _vm.isNewForm,
        },
        on: { closed: _vm.drawerClosed },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }